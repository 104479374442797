import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: true,
  auth: {
    isLocalLoginEnabled: false,
    // TODO redirect URL
    redirectUrl: 'https://www.radioking.com/login?redirect=https:%2F%2Fmobile.radioking.com',
  },
  urls: {
    MAIN_API: 'https://api.radioking.io',
    MOBILE_API: 'https://www.radioking.com/mobile/api',
    RADIOKING_DOMAIN: 'radioking.com',
    SIMULATOR: 'https://mobile-simulator.radioking.com'
  },
  whmcs: {
    url: 'https://www.radioking.com/on',
    start: {
      customFieldId: '137',
      planId: '53',
      publicationAddonId: '15',
    },
    pro: {
      customFieldId: '7',
      planId: '7',
      publicationAddonId: '15',
    },
    business: {
      customFieldId: '140',
      planId: '56',
      publicationAddonId: '17',
    },
  },
  googleMapsKey: 'AIzaSyBR1nDNYA2CUMvUJ4ACd-8hxmoEgJGYMF0',
  gtm: 'GTM-MJPC7G6',
  amplitudeKey: '758ff1fd961d199cf6bba3e391d4625c',
  cookies: {
    config: {
      domain: '.radioking.com',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
};
